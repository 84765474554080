<template>
  <div v-loading="loading" style="width: 100%; height: 100vh"></div>
</template>
<script>
import { emailVerify } from '@/api/userAction';
import { mapActions } from 'vuex';
export default {
  name: 'verify',
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    ...mapActions(['doLogin']),
  },
  created() {
    console.log(this.$route.query.token);
    if (!this.$route.query.token || sessionStorage.getItem('session')) {
      this.$router.push('/register');
    } else {
      emailVerify(this.$route.query.token)
        .then((res) => {
          this.doLogin(res);
          this.$message.success('驗證成功');
          this.$router.push('/');
        })
        .catch((error) => {
          this.$alert(error.message, 'ERROR!', {
            confirmButtonText: '確定',
            callback: () => {
              this.$router.push('/register');
            },
          });
        });
    }
  },
};
</script>
